/* eslint-disable no-param-reassign */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import Pager from '@core/dev-extreme/utils/pager'

function sortFieldMap(sort) {
  const sortBy = []
  sort.forEach(c => {
    let field = c.selector
    if (c.selector === 'itemCount') field = 'quantity'
    sortBy.push({ selector: field, desc: c.desc })
  })
  return sortBy
}

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? sortFieldMap(loadOptions.sort) : [{ selector: 'id', desc: true }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortOrder(sort)

    const pageableQuery = `${pager.toCreatePageable()}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const result = await outboundShippingService.queryMerchantShipments(filters, pageableQuery)
    return {
      data: result.content,
      totalCount: result.totalElements,
    }
  },
})

const fbmShipmentsDataSource = new DataSource({
  store: store,
  requireTotalCount: true,
})

export {
  fbmShipmentsDataSource as FbmShipmentsDataSource,
}
