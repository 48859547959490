<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="fbmShipmnetsGrid"
          ref="fbmShipmentsGridRef"
          :data-source="FbmShipmentsDataSource"
          :height="setHeight"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          :customize-columns="customizeColumns"
          @option-changed="onOptionChanged"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
          @editor-preparing="onEditorPreparing"
          @exporting="onExporting"
        >
          <!--region    ::DataGird base components -->
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <!-- <dx-export :enabled="true" :allow-export-selected-data="true" /> -->
          <dx-selection mode="multiple" show-check-boxes-mode="always"
            select-all-mode="page" :allow-select-all="true"
            :width="10"
            />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="120" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                ref="btnAddCharge"
                class="mr-half"
                type="default" icon="bi bi-grid-3x3-gap-fill"
                hint="Open Shipment Items"
                @click="openShipmentItemsPopup(data.data)"
              />
              <dx-util-button
                ref="btnAddCharge"
                type="success" icon="bi bi-coin"
                hint="Charge Details"
                @click="onClickAddCharges(data)"
              />
            </div>
          </template>
          <!--endregion ::DataGird base components -->
          <dx-column data-field="boxName" cell-template="boxNameTemplate" />
          <template #boxNameTemplate="{data}">
            <div class="dx-product-title">
              {{ data.value }}
            </div>
          </template>
          <dx-column v-if="hasPermission" data-field="accountNo" caption="Acc #" :width="75" cell-template="accountCell" :visible="isServingTenant" />
          <dx-column v-if="hasPermission" data-field="suiteNo" caption="Suite #" cell-template="accountCell" :visible="isServingTenant" />
          <template #accountCell="{ data }">
            <a href="#" @click="onFilterByAccountNoLink($event, data)">
              <span>{{ data.value }}</span>
            </a>
          </template>
          <dx-column data-field="companyName" caption="Company" :visible="isServingTenant" />
          <dx-column data-field="storeName" caption="Store" />
          <dx-column data-field="orderNumber" caption="Order Number" :allow-sorting="false" />
          <dx-column data-field="tracking" caption="Tracking Link" :allow-exporting="false" alignment="left" :width="230" cell-template="asinCellTemplate" />
          <template #asinCellTemplate="{data}">
            <a target="_blank" :href="`${data.data.trackingLink}`">
              {{ data.data.trackingNumber }}
            </a>
          </template>
          <dx-column data-field="totalCost" caption="Total Cost" :allow-sorting="false" :width="90" />
          <dx-column data-field="itemCount" caption="Item QTY" :width="85" />
          <dx-column data-field="shipTime" caption="Shipped At" data-type="date" :width="90" />
          <dx-column data-field="warehouse" caption="Warehouse" :visible="isServingTenant" />
          <dx-column
            data-field="invoiceId"
            caption="Invoice"
            cell-template="invoiceTemp"
            :visible="isServingTenant"
          />
          <dx-column data-field="notes" />
          <template #invoiceTemp="{data}">
            <router-link
              v-if="data.value"
              :to="{ name: 'apps-invoice-preview', params: { id: data.value } }"
            >
              <span class="text-capitalize d-block badge badge-light-success badge-pill">
                Invoice
              </span>
            </router-link>
          </template>
          <template #filterToolbar>
            <div class="d-flex flex-row align-items-center">
              <div v-if="hasPermission" class="mr-1">
                <dx-util-text-box
                  v-model="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="getFbmShipmentsDataByAccount"
                  @value-changed="getFbmShipmentsDataByAccount"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedWarehouse"
                  :data-source="warehouses"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedStore"
                  :data-source="stores"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedDateRange"
                  :data-source="dateRangesList"
                  width="140px"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="setDateRanges"
                />
              </div>
              <div v-show="isCustomDateRange" class="mr-1">
                <dx-util-date-box v-model="beginDate" :max="endDate" type="date" @value-changed="searchByFilter" />
              </div>
              <div v-show="isCustomDateRange" class="mr-1">
                <dx-util-date-box v-model="endDate" :min="beginDate" type="date" @value-changed="searchByFilter" />
              </div>
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
    <div>
      <fbm-shipment-items-popup :component-id="fbmShipmentItemsCompId" :shipment-details="selectedShipment" />
    </div>
    <div class="col-md-12">
      <!--Begin:: Charge Component-->
      <dx-util-popup
        id="popupCharges"
        ref="popupChargesRef"
        :show-close-button="true"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="true"
        :height="720"
        content-template="popup-content"
        :title="chargePopupTitle"
        @shown="onShownChargesPopup"
        @hiding="onHidingChargesPopup"
      >
        <dx-util-position at="center" my="center" />
        <template #popup-content>
          <charges v-if="isCreated" :item-id="itemId" :read-only="!hasPermission" :load-with-item-id="true" item-type="box" @on-emit-get-charges="onEmitGetCharges" />
        </template>
        <dx-util-toolbar-item v-if="hasPermission" :visible="chargeUpdateAllowed" widget="dxButton" toolbar="bottom" location="after" :options="saveChargesOptions" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="closeChargesOptions" />
      </dx-util-popup>
      <!--End:: Charge Component-->
    </div>
  </div>
</template>

<script>
import Pager from '@core/dev-extreme/utils/pager'
import { Notify } from '@robustshell/utils/index'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { formatDate } from '@core/utils/filter'
import predefinedDateRangesEnum, { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum.js'
import useDateRanges from '@/@core/composables/useDateRanges.js'
import { emitter } from '@/libs/bus'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import FbmShipmentsFilter from '@/http/models/search-filters/FbmShipmentsFilter'
import Charges from '@components/charge/Charges.vue'
import itemChargeService from '@/http/requests/finance/itemChargeService'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import useCurrentUser from '@/libs/app/current-user'
// import FbmShipmentItems from './FbmShipmentItems.vue'
import { FbmShipmentsDataSource } from './fbmShipmentsStore'

export default {
  components: {
    Charges,
    // 'fbm-shipment-items': FbmShipmentItems,
    'fbm-shipment-items-popup': () => import('./components/FBMShipmentItemsPopup.vue'),
  },
  mixins: [GridBase, Filters],
  data() {
    const today = new Date()
    const last30Days = new Date()
    last30Days.setDate(today.getDate() - 30)
    last30Days.setHours(0, 0, 0, 0)
    const plusOneDay = new Date(today)
    plusOneDay.setDate(today.getDate() + 1)
    plusOneDay.setHours(0, 0, 0, 0)
    return {
      FbmShipmentsDataSource,
      filters: {
        query: '',
      },
      itemId: null,
      isCreated: false,
      chargeUpdateAllowed: false,
      chargePopupTitle: '',
      accountNo: '',
      beginDate: last30Days,
      endDate: plusOneDay,
      selectedDateRange: predefinedDateRangesEnum.LAST30DAYS.value,
      dateRangesList: getPredefinedDateRangesList(),
      includeDetails: false,
      fbmShipmentItemsCompId: '',
      selectedShipment: {},
    }
  },
  computed: {
    dataGrid() {
      const grid = this.$refs.fbmShipmentsGridRef.instance
      return grid
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    assignLocationPopup() {
      return this.$refs.assignLocationPopupRef.instance
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e.event)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.assignLocationPopup.hide()
        },
      }
    },
    saveChargesOptions() {
      return {
        text: 'Save Charges',
        type: 'success',
        onClick: () => {
          emitter.emit('on-emit-set-charges')
        },
      }
    },
    closeChargesOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          const popup = this.$refs.popupChargesRef.instance
          popup.hide()
        },
      }
    },
  },
  setup() {
    const { setDateRanges, startDate, isCustomDateRange } = useDateRanges()
    const { isServingTenant } = useCurrentUser
    return {
      setDateRanges, startDate, isCustomDateRange, isServingTenant,
    }
  },
  watch: {
    selectedDateRange() {
      const plusOneDay = new Date()
      plusOneDay.setDate(plusOneDay.getDate() + 1)
      this.beginDate = this.startDate || this.beginDate
    },
  },
  mounted() {
    this.getWarehouses()
    this.getStoresForCurrentCompany()
    this.initialLoad()
  },
  methods: {
    openShipmentItemsPopup(data) {
      this.fbmShipmentItemsCompId = this.$uid()
      this.selectedShipment = data
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        FbmShipmentsDataSource.pageIndex(0)
        FbmShipmentsDataSource.reload()
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          dropDownOptions: {
            width: 240,
          },
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Items',
              icon: 'selectall',
            },
            {
              id: 'allWithBoxInfo',
              name: 'All Items with Box Info',
              icon: 'export',
            },
            {
              id: 'selected',
              name: 'Selected Items',
              icon: 'exportselected',
            },
            {
              id: 'selectedWithBoxInfo',
              name: 'Selected Items with Box Info',
              icon: 'exportxlsx',
            },
          ],
          width: 120,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download Shipment Items',
          onItemClick: arg => {
            switch (arg.itemData.id) {
              case 'allWithBoxInfo':
                this.includeDetails = true
                this.onDownloadItems()
                break
              case 'selected':
                this.includeDetails = false
                this.onDownloadSelectedItems()
                break
              case 'selectedWithBoxInfo':
                this.includeDetails = true
                this.onDownloadSelectedItems()()
                break
              default:
                this.includeDetails = false
                this.onDownloadItems()
                break
            }
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reload()
          },
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    initialLoad() {
      FbmShipmentsFilter.setDefaultFilters()
      FbmShipmentsFilter.beginDate = this.beginDate
      FbmShipmentsFilter.endDate = this.endDate
      const filters = FbmShipmentsFilter.getFilters()
      FbmShipmentsDataSource.searchValue(filters)
      FbmShipmentsDataSource.load()
    },
    onFilterByAccountNoLink(e, row) {
      this.accountNo = row.value.toString()
      this.getStores(this.accountNo)
      this.searchByFilter()
    },
    searchByFilter() {
      FbmShipmentsFilter.setDefaultFilters()
      FbmShipmentsFilter.accountNo = this.accountNo
      FbmShipmentsFilter.warehouseId = this.selectedWarehouse
      FbmShipmentsFilter.storeId = this.selectedStore
      FbmShipmentsFilter.q = this.filters.query
      FbmShipmentsFilter.beginDate = this.beginDate
      FbmShipmentsFilter.endDate = this.endDate
      const filters = FbmShipmentsFilter.getFilters()
      FbmShipmentsDataSource.searchValue(filters)
      FbmShipmentsDataSource.reload()
    },
    reload() {
      FbmShipmentsFilter.setDefaultFilters()
      this.accountNo = FbmShipmentsFilter.accountNo
      this.selectedWarehouse = FbmShipmentsFilter.warehouseId
      this.selectedStore = FbmShipmentsFilter.storeId
      this.selectedDateRange = predefinedDateRangesEnum.LAST30DAYS.value
      const today = new Date()
      const last30Days = new Date()
      last30Days.setDate(today.getDate() - 30)
      last30Days.setHours(0, 0, 0, 0)
      const plusOneDay = new Date(today)
      plusOneDay.setHours(0, 0, 0, 0)
      plusOneDay.setDate(plusOneDay.getDate() + 1)
      FbmShipmentsFilter.beginDate = last30Days
      FbmShipmentsFilter.endDate = plusOneDay
      this.getStores(this.accountNo)
      const filters = FbmShipmentsFilter.getFilters()
      FbmShipmentsDataSource.searchValue(filters)
      FbmShipmentsDataSource.reload()
    },
    getFbmShipmentsDataByAccount(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.selectedStore = 0
        this.getStores()
        this.searchByFilter()
      }
    },
    isActiveChargeAction(e) {
      return e.row.data.invoiceId && e.row.data.invoiceId !== null
    },
    onClickAddCharges(e) {
      this.chargeUpdateAllowed = !this.isActiveChargeAction(e)
      const { data } = e.row
      this.itemId = data.id
      const popup = this.$refs.popupChargesRef.instance
      popup.show()
      this.$nextTick(() => {
        this.isCreated = true
      })
    },
    onShownChargesPopup() {
      this.chargePopupTitle = 'Shipment Charges'
    },
    onHidingChargesPopup() {
      this.$nextTick(() => {
        this.itemId = null
        this.isCreated = false
      })
    },
    onEmitGetCharges(payload) {
      const charges = payload
      itemChargeService.create(this.itemId, charges).then(() => {
        const popup = this.$refs.popupChargesRef.instance
        popup.hide()
      })
    },
    async onDownloadItems(e) {
      const filters = FbmShipmentsFilter.getFilters()
      const pager = new Pager({})
      const max = 2000
      pager.setPageNumber(0, max)
      const pageableQuery = `${pager.toCreatePageable()}`
      const response = await outboundShippingService.findBoxesByQuery(filters, pageableQuery)
      const data = response.data.content
      if (data === undefined || data === null || data?.length <= 0) {
        Notify.warning('There are not any items to download.')
        return
      }
      this.onDownloadExportData(data)
    },
    onDownloadSelectedItems() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('FBM Shipment Items')
      if (this.includeDetails) {
        worksheet.columns = [
          { header: 'Box Name', key: 'BoxName', width: 14 },
          { header: 'Title', key: 'title', width: 12 },
          { header: 'ASIN', key: 'asin', width: 12 },
          { header: 'UPC', key: 'upc', width: 12 },
          { header: 'MSKU', key: 'msku', width: 12 },
          { header: 'Detail Quantity', key: 'quantity', width: 12 },
          { header: 'Receive Time', key: 'receiveTime', width: 12 },
          { header: 'Ship Time', key: 'shipTime', width: 12 },
          { header: 'Status', key: 'status', width: 12 },
          { header: 'Detail Notes', key: 'notes', width: 12 },
          { header: 'Store', key: 'storeName', width: 12 },
          { header: 'Order Number', key: 'orderNumber', width: 10 },
          { header: 'Tracking Number', key: 'track.trackNumber', width: 10 },
          { header: 'Total Cost', key: 'totalCost', width: 13 },
          { header: 'Total QTY', key: 'itemCount', width: 18 },
          { header: 'Shipped At', key: 'shipTime', width: 18 },
          { header: 'Warehouse', key: 'warehouseName', width: 14 },
          { header: 'Notes', key: 'notes', width: 13 },
        ]
      } else {
        worksheet.columns = [
          { header: 'Box Name', key: 'boxName', width: 14 },
          { header: 'Store', key: 'storeName', width: 12 },
          { header: 'Order Number', key: 'orderNumber', width: 10 },
          { header: 'Tracking Link', key: 'track.trackNumber', width: 10 },
          { header: 'Total Cost', key: 'totalCost', width: 13 },
          { header: 'Item QTY', key: 'itemCount', width: 18 },
          { header: 'Shipped At', key: 'shipTime', width: 18 },
          { header: 'Warehouse', key: 'warehouseName', width: 14 },
          { header: 'Notes', key: 'notes', width: 13 },
        ]
      }

      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:AB1'
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

      if (this.includeDetails) {
        data.forEach(item => {
          item.items.forEach(el => {
            worksheet.addRow([
              item.boxName,
              el.title,
              el.asin,
              el.upc,
              el.msku,
              el.quantity,
              formatDate(el.receiveTime),
              formatDate(el.shipTime),
              el.status,
              el.notes,
              item.storeName,
              item.orderNumber,
              item.track.trackNumber,
              item.totalCost,
              item.itemCount,
              formatDate(item.shiptime),
              item.warehouseName,
              item.notes,
            ])
          })
        })
      } else {
        data.forEach(item => {
          worksheet.addRow([
            item.boxName,
            item.storeName,
            item.orderNumber,
            item.track.trackNumber,
            item.totalCost,
            item.itemCount,
            formatDate(item.shiptime),
            item.warehouseName,
            item.notes,
          ])
        })
      }
      workbook.xlsx.writeBuffer().then(buffer => {
        const fileName = Date.now()
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `FBM-Shipments-${fileName}.xlsx`,
        )
      })
    },
    formatItems(items) {
      return items.map(item => ({
        title: item.title,
        asin: item.asin,
        upc: item.upc,
        msku: item.msku,
        quantity: item.quantity,
        receiveTime: formatDate(item.receiveTime),
        shipTime: formatDate(item.shipTime),
        status: item.status,
        notes: item.notes,
      }))
    },
  },
}
</script>

<style lang="scss"></style>
